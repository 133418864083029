import Highway from "@dogstudio/highway";
import Slider from "../components/Slider";

const aboutButton = document.querySelector("#about-button");
let slider;

class About extends Highway.Renderer {
  onEnter() {
    aboutButton.classList.add("is-active");
    slider = new Slider(document.querySelector(".slider"));
  }

  onLeave() {
    aboutButton.classList.remove("is-active");
		slider.destroy()
  }
}

export default About;
