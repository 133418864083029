const ProjectInfo = (wrapper) => {
  const { id } = wrapper.dataset;
  const openButton = document.querySelector(`.project[data-id=${id}] button`);
  const closeButton = wrapper.querySelector("button.close");

  let isOpen = false;

  const open = () => {
    setTimeout(() => {
      if (isOpen) return;
      isOpen = true;

      wrapper.classList.add("is-open");
      document.body.classList.add("project-open");
      document.documentElement.style.cssText = "overflow-y: hidden;";
    }, 0);
  };
  const close = () => {
    if (!isOpen) return;
    isOpen = false;

    wrapper.classList.remove("is-open");
    document.body.classList.remove("project-open");
    document.documentElement.style.cssText = "";
  };

  openButton.addEventListener("click", open);
  closeButton.addEventListener("click", close);
  document.body.addEventListener("click", (e) => {
    if (!e.target.closest(".project-info")) close();
  });

  return {
    destroy: () => {
      document.body.removeEventListener("click", close);
    },
  };
};

export default ProjectInfo;
