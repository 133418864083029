import { io } from "@maaax/utils/Core/io";
import Emitter from "../utils/Emitter";
import getColorLuma from "../utils/getColorLuma";

const Project = (wrapper, index) => {
  const { color, textColor } = wrapper.dataset;
  let isVisible = false;

  const onVisible = () => {
    if (!isVisible) return;

    document.body.style.background = color;
    document.body.style.setProperty(
      "--text-color",
      textColor ? textColor : getColorLuma(color) < 40 ? "var(--white)" : "var(--black)"
    );

		// Update number eg.: 03/07
    document.querySelector("#project-number-wrapper .current").innerText =
      index < 10 ? `0${index}` : index;
  };

  io({
    el: wrapper,
    fnIn: () => {
      isVisible = true;
      onVisible();
    },
    fnOut: () => {
      isVisible = false;
      Emitter.emit("project-out");
    },
  });

  Emitter.on("project-out", onVisible);

  return {
    destroy: () => {
      Emitter.off("project-out", onVisible);
    },
  };
};

export default Project;
