import Highway from "@dogstudio/highway";

import About from "./pages/about";
import Home from "./pages/home";
import DefaultTransition from "./transitions/DefaultTransition";

new Highway.Core({
  renderers: {
    home: Home,
    about: About,
  },
  transitions: {
    default: DefaultTransition,
  },
});

document.fonts.ready.then(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
    document.body.classList.add("is-loaded");
  }, 250);
});  
