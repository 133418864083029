import Highway from "@dogstudio/highway";

import ProjectInfo from "../components/ProjectInfo";
import Project from "../components/Project";
import AutoplayVideo from "../components/AutoplayVideo";

let projectInfos = [];
let projects = [];

const homeButton = document.querySelector("#home-button");

class Home extends Highway.Renderer {
  onEnter() {
    homeButton.classList.add("is-active");

    document.querySelectorAll(".project-info").forEach((el) => {
      projectInfos.push(ProjectInfo(el));
    });

    document.querySelectorAll(".project").forEach((el, i) => {
      projects.push(Project(el, i + 1));
    });

    document.querySelectorAll(".media video").forEach((el) => {
      AutoplayVideo(el.parentNode);
    });
  }

  onLeave() {
    // Reset styles
    homeButton.classList.remove("is-active");
    document.body.style.background = "var(--white)";
    document.body.style.setProperty("--text-color", "var(--black)");

    // Destroy components
    projectInfos.forEach((el) => el.destroy());
    projects.forEach((el) => el.destroy());
  }
}

export default Home;
