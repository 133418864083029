import Highway from "@dogstudio/highway";

class DefaultTransition extends Highway.Transition {
  in({ from, to, trigger, done }) {
    window.scrollTo(0, 0);
    from.remove();

    document.querySelector("#transition-loader").classList.remove("is-visible");

    done();
  }

  out({ from, trigger, done }) {
    document.querySelector("#transition-loader").classList.add("is-visible");

    setTimeout(() => {
      done();
    }, 700);
  }
}

export default DefaultTransition;