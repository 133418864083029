import { io } from "@maaax/utils/Core/io";

const AutoplayVideo = (wrapper) => {
  const video = wrapper.querySelector("video");

  io({
    el: wrapper,
    fnIn: () => {
      video.play();
    },
    fnOut: () => {
      video.pause();
    },
  });
};

export default AutoplayVideo;
