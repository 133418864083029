import { bind, raf, clamp, lerp, io } from "@maaax/utils";
import Emitter from "../utils/Emitter";

class Slider {
  constructor(el) {
    if (!el) return;

    bind(this, [
      "next",
      "prev",
      "run",
      "on",
      "off",
      "start",
      "stop",
      "onMove",
      "onResize",
    ]);

    this.dom = {
      el,
      next: el.querySelector(".next"),
      prev: el.querySelector(".prev"),
      slider: el.querySelector(".slides-wrapper"),
      slides: el.querySelectorAll(".slide"),
      videos: el.querySelectorAll("video"),
    };

    this.dom.slidesL = this.dom.slides.length;

    this.options = {
      speed: 2.5,
      ease: 0.14,
      snap: true,
    };

    this.data = {
      min: 0,
      max: 0,
      isDragging: false,

      curX: 0,
      lastX: 0,
      endX: 0,

      raf: null,
    };

    io({
      el,
      fnIn: this.start,
      fnOut: this.stop,
    });

    this.dom.videos.forEach((video) => {
      io({
        el: video.parentNode,
        fnIn: () => {
          video.play();
        },
        fnOut: () => {
          video.pause();
        },
      });
    });

    this.getBounds();
    this.addEvents();
  }

  addEvents() {
    // Slider Events
    if (this.dom.next) this.dom.next.addEventListener("click", this.next);
    if (this.dom.next) this.dom.prev.addEventListener("click", this.prev);

    this.dom.el.addEventListener("mousedown", this.on);
    this.dom.el.addEventListener("touchstart", this.on, { passive: true });
    this.dom.el.addEventListener("mouseup", this.off);
    this.dom.el.addEventListener("touchend", this.off, { passive: true });
    this.dom.el.addEventListener("mousemove", this.onMove);
    this.dom.el.addEventListener("touchmove", this.onMove, { passive: true });

    Emitter.on("resize", this.onResize);
  }

  findClosest(goal, array) {
    if (array.length === 0) return 0;

    const closest = array.reduce(function (prev, curr) {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
    });

    return array.indexOf(closest);
  }

  getBounds() {
    this.snapPoints = [];

    for (let i = 0; i < this.dom.slidesL; i++) {
      const bounds = this.dom.slides[i].getBoundingClientRect();

      if (i === 0) {
        this.centerX = bounds.left + bounds.width * 0.5;
      }

      // Calculate the center of the slide
      const diff = this.data.curX - this.data.lastX;
      const center = bounds.x + diff + bounds.width * 0.5;
      const snapPoint = this.centerX - center;

      this.snapPoints.push(snapPoint);
    }

    this.data.max = this.snapPoints[this.dom.slidesL - 1];
  }

  onResize() {
    this.getBounds();

    // Put slides on last position
    const curSnappoint = this.findClosest(this.data.lastX, this.snapPoints);
    const curSnapPos = this.snapPoints[curSnappoint];
    if (curSnapPos)
      this.data.curX = this.data.lastX = this.data.endX = curSnapPos;
  }

  destroy() {
    this.stop();

    if (this.dom.next) this.dom.next.removeEventListener("click", this.next);
    if (this.dom.next) this.dom.prev.removeEventListener("click", this.prev);

    this.dom.el.removeEventListener("mousedown", this.on);
    document.body.removeEventListener("mouseup", this.off);
    this.dom.el.removeEventListener("mousemove", this.onMove);

    this.dom.el.removeEventListener("touchstart", this.on);
    document.body.removeEventListener("touchend", this.off);
    this.dom.el.removeEventListener("touchmove", this.onMove, true);
  }

  start() {
    this.data.raf = raf.add(this.run);
  }

  stop() {
    raf.remove(this.data.raf);
    this.data.markedForStop = false;
  }

  // On mouse/touch down
  on(e) {
    if (this.data.isDragging) return;
    this.data.startX = e.clientX || e.touches[0].pageX;
    this.data.startY = e.clientY || e.touches[0].pageY;

    this.data.isDragging = true;
  }

  // On mouse up / touch end
  off(e) {
    if (!this.data.isDragging) return;

    // Set snap position
    if (this.options.snap) {
      const index = this.findClosest(this.data.curX, this.snapPoints);

      // Set the position to snap to
      this.data.curX = this.snapPoints[index];
    }
    this.data.endX = this.data.curX;

    this.data.isDragging = false;
  }

  onMove(e) {
    if (!this.data.isDragging) return;

    const cur = e.clientX || e.touches[0].pageX;

    this.data.curX =
      this.data.endX + (cur - this.data.startX) * this.options.speed;
    this.data.curX = clamp(this.data.curX, this.data.max, this.data.min);
  }

  run() {
    this.data.lastX = lerp(this.data.lastX, this.data.curX, this.options.ease);
    // this.data.lastX = round(this.data.lastX);

    // Update the slider position
    this.dom.slider.style.cssText = `transform: translate3d(${this.data.lastX}px, 0px, 0px);`;
  }

  // Click on next button
  next() {
    const curSnappoint = this.findClosest(this.data.lastX, this.snapPoints);
    const nextSnapPoint = curSnappoint + 1;

    const newSnapPos = this.snapPoints[nextSnapPoint];

    if (newSnapPos) this.data.curX = this.data.endX = newSnapPos;
  }

  // Click on previous button
  prev() {
    const curSnappoint = this.findClosest(this.data.lastX, this.snapPoints);
    const nextSnapPoint = curSnappoint - 1;

    const newSnapPos = this.snapPoints[nextSnapPoint];

    if (newSnapPos || newSnapPos === this.data.min)
      this.data.curX = this.data.endX = newSnapPos;
  }
}

export default Slider
